import {
  IconGauge, IconHome,
  IconRulerMeasure,
  IconTool
} from "@tabler/icons-react";
import { NavType } from "../types/naviguation.types";

const navData: NavType[] = [
  {
    label: "Dashboard",
    icon: IconHome,
    links: [
      {
        label: "Difference",
        link: "/",
      },
      {
        label: "Rebuild",
        link: "/rebuild",
      },
      {
        label: "Label",
        link: "/label",
      },
      {
        label: "Widget",
        link: "/widget",
      },
    ],
  },
  {
    label: "State & Rule",
    icon: IconRulerMeasure,
    links: [
      {
        label: "State",
        link: "/state",
      },
      {
        label: "Rule",
        link: "/state/rule",
      },
      {
        label: "Assignement",
        link: "/state/assignement",
      }
    ],
  },
  {
    label: "Model",
    icon: IconGauge,
    links: [
      {
        label: "Summary",
        link: "/model",
      },
      {
        label: "Exclusion",
        link: "/model/exclusion",
      },
      {
        label: "Train",
        link: "/model/train",
      },
      {
        label: "Predict",
        link: "/model/predict",
      },
    ],
  },
  {
    label: "Tools",
    icon: IconTool,
    links: [
      {
        label: "Classifier",
        link: "/classifier",
      },
    ],
  },
];

export default navData;
