import { Paper, Text, Title } from "@mantine/core";
import UpdateStateTenant from "../../components/UpdateStateTenant";

const StatePage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">Manage States</Title>
      <Text mb="xl">
        Define and update states
      </Text>
      <UpdateStateTenant />
    </Paper>
  );
};

export default StatePage;
