import { Flex, Loader, Paper, Text, Title } from "@mantine/core";
import useSWR from "swr";
import { useApiClient } from "../../ApiClientProvider";
import { BackendClient } from "../../generated";
import useExcelStore from "../../hooks/useExcelStore";
import UsersTable from "../UserTable";

const fetchTenantUsers = async (apiClient: BackendClient, identifier: string | undefined) => {
  if (!identifier) {
    console.error("Identifier is required");
    return;
  }
  try {
    const response =
      await await apiClient.user.getListTenantsTenantIdUsersGet(identifier);
    return response;
  } catch (error) {
    throw error;
  }
};

const TenantUsersInfo = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const tenant_identifier = sheetsData.Description?.tenant_identifier;
  const { data, error, isLoading } = useSWR(
    tenant_identifier ? ["tenantUsers", tenant_identifier] : null,
    () => fetchTenantUsers(apiClient, tenant_identifier),
    {
      shouldRetryOnError: false,
    }
  );

  if (!tenant_identifier) {
    return null;
  }

  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Flex
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="column"
      >
        <Title order={2}>Users in Database</Title>
        {isLoading && (
          <>
            <Text>Loading users...</Text>
            <Loader />
          </>
        )
        }
        {!isLoading && error && (
          <Text>
            No users were found for {tenant_identifier} in the database
          </Text>
        )}
        {!isLoading && data && <UsersTable users={data} />}
      </Flex>
    </Paper >
  );
};

export default TenantUsersInfo;
