/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ClassifierService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Classify Parameters
     * Use OpenAPI to classify parameters into categories.
     *
     * The classifier uses GPT-3 to classify parameters into Feature, Condition, or Mix.
     * @param parameters List of parameters to classify
     * @param xTenantId
     * @returns string Successful Response
     * @throws ApiError
     */
    public classifyParametersClassifierPost(
        parameters: Array<string>,
        xTenantId?: string,
    ): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/classifier',
            query: {
                'parameters': parameters,
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
