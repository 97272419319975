import { Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import NotFoundPage from "../components/NotFound";
import { routerType } from "../types/router.types";
import pagesData from "./pagesData";

const Router = () => {
  const pageRoutes = pagesData.map(({ path, key, element }: routerType) => {
    return (
      <Route key={key} path={`/${path}`} element={<Layout>{element}</Layout>} />
    );
  });

  return (
    <Routes>
      {pageRoutes} <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
