/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DashboardConfig } from './DashboardConfig';
import type { EdgeDeviceEndpoint } from './EdgeDeviceEndpoint';
import type { TenantState } from './TenantState';

export type Tenant = {
    basetype?: Tenant.basetype;
    id: string;
    identifier: string;
    allowed_ips?: Array<string>;
    label?: string;
    retention_days?: number;
    edge_device_endpoints?: Array<EdgeDeviceEndpoint>;
    resolution?: number;
    state: TenantState;
    data_schema?: string;
    train_distance?: number;
    dashboard_config?: DashboardConfig;
};

export namespace Tenant {

    export enum basetype {
        TENANT = 'Tenant',
    }


}

