import { Button } from "@mantine/core";
import useExcel from "../../hooks/useExcel";
import usePicker from "../../hooks/usePicker";

const PickerButton = ({ children }: { children: React.ReactNode }) => {
  const { launchPicker, pickedFile } = usePicker();
  const { loading } = useExcel(pickedFile);

  return <Button onClick={launchPicker} loading={loading}>{children}</Button>;
};

export default PickerButton;
