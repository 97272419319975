import { Button, Loader, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { useApiClient } from "../../ApiClientProvider";
import { ApiError, BackendClient, EdgeData, EdgeDeviceEndpoint } from "../../generated";
import useEnvironmentStore from "../../hooks/useEnvironmentStore";
import useExcelStore from "../../hooks/useExcelStore";


const fetchTenant = async (apiClient: BackendClient, identifier: string | undefined) => {
  if (!identifier) {
    throw new Error("Identifier is required");
  }
  return await apiClient.tenant.getTenantsTenantIdGet(
    identifier,
  );
};

const checkIfValid = (sheetEdgeEndpoints: EdgeData[] | undefined, apiEdgeEndpoints: EdgeDeviceEndpoint[] | undefined) => {
  if (!sheetEdgeEndpoints || !apiEdgeEndpoints) return false;
  const sheetEndpoints = sheetEdgeEndpoints.map((edge) => {
    return {
      heartbeat_interval: edge["Heartbeat Interval"],
      active_endpoint: edge["Active Endpoint"],
      active_edgedevice: edge["Active Edgedevice"],
    };
  });
  const apiEndpoints = apiEdgeEndpoints.map((edge) => {
    return {
      heartbeat_interval: edge.heartbeat_interval,
      active_endpoint: edge.active_endpoint,
      active_edgedevice: edge.active_edgedevice,
    };
  });
  return sheetEndpoints.every((sheetEdge) => {
    return apiEndpoints.some((apiEdge) => {
      return (
        sheetEdge.heartbeat_interval === apiEdge.heartbeat_interval &&
        sheetEdge.active_endpoint === apiEdge.active_endpoint &&
        sheetEdge.active_edgedevice === apiEdge.active_edgedevice
      );
    });
  });
}

const UpdateEdgeDevices = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const [isUpdating, setIsUpdating] = useState(false);
  const tenant_identifier = sheetsData.Description?.tenant_identifier;
  const edge_devices = sheetsData.Edge;

  const { data, error, isLoading } = useSWR(
    tenant_identifier ? ["tenant", tenant_identifier] : null,
    () => fetchTenant(apiClient, tenant_identifier),
    {
      shouldRetryOnError: false,
    }
  );

  const updateEdgeDevices = async () => {
    try {
      if (!tenant_identifier) throw new Error("Tenant identifier is required");
      if (!edge_devices) throw new Error("Edge devices are required");

      setIsUpdating(true);
      await apiClient.edgeDevice.updateTenantsTenantIdEdgedevicesPut(
        tenant_identifier,
        edge_devices
      );
      toast.success("Edge devices updated successfully!");
      mutate(["tenant", tenant_identifier]);
    } catch (error) {
      let errorMessage = 'Failed to update edge devices: An unknown error occurred';
      if (error instanceof ApiError) {
        if (error.body && typeof error.body === 'object') {
          errorMessage = `Failed to update edge devices: ${error.body.detail || error.statusText}`;
        }
      } else if (error instanceof Error) {
        errorMessage = `Failed to update edge devices: ${error.message}`;
      }
      toast.error(errorMessage);
    } finally {
      setIsUpdating(false);
    }
  };

  const isValid = checkIfValid(edge_devices, data?.edge_device_endpoints);

  const { setMaxActiveStep } = useEnvironmentStore();
  useEffect(() => {
    setMaxActiveStep(isValid);
  }, [isValid, setMaxActiveStep]);

  if (isLoading) {
    return (
      <>
        <Text>Loading edge endpoints for <b>{tenant_identifier}</b>...</Text>
        <Loader />
      </>
    );
  }

  return (
    <>
      {isValid ? (
        <Text>Edge endpoints are already up-to-date</Text>
      ) : (
        <>
          <Text>Edge endpoints need to be updated</Text>
          <Button onClick={updateEdgeDevices} loading={isUpdating}>
            Update Edge Endpoints
          </Button>
        </>
      )}
      {error && (
        <Text>
          Failed to fetch edge endpoints: {error.message}
        </Text>
      )}
    </>
  );
};

export default UpdateEdgeDevices;
