/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EdgeData } from '../models/EdgeData';
import type { GenericResponse } from '../models/GenericResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EdgeDeviceService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Update
     * Update the list of edge devices for a tenant.
     * @param tenantId
     * @param requestBody
     * @param xTenantId
     * @returns GenericResponse Successful Response
     * @throws ApiError
     */
    public updateTenantsTenantIdEdgedevicesPut(
        tenantId: string,
        requestBody: Array<EdgeData>,
        xTenantId?: string,
    ): CancelablePromise<Array<GenericResponse>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/tenants/{tenant_id}/edgedevices',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
