/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible MlProcessor Types
 */
export enum MlProcessorType {
    DENSITY_ESTIMATION = 'DensityEstimation',
}
