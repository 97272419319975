import {
  Button,
  ComboboxItem,
  Group,
  Loader,
  Modal,
  Paper,
  Select,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure, useListState } from "@mantine/hooks";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { useApiClient } from "../../ApiClientProvider";
import { BackendClient, EntityEnum, WidgetType } from "../../generated";
import DndWidgets from "../DndWidgets";
import WidgetForm from "../WidgetForm";

const fetchAllTenants = (apiClient: BackendClient) =>
  apiClient.tenant.getListTenantsGet();

const fetchTimeSeriesAndStructuralData = (
  apiClient: BackendClient,
  tenant: string | null,
) => {
  if (!tenant) return;
  return apiClient.entities.getEntitiesTenantsTenantIdEntitiesGet(
    tenant,
    [EntityEnum.TIME_SERIES, EntityEnum.STRUCTURAL_COMPONENT],
  );
};

const UpdateWidgets = () => {
  const apiClient = useApiClient();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedTenant, setSelectedTenant] = useState<string | null>(null);
  const [selectedStructuralComponent, setSelectedStructuralComponent] =
    useState<ComboboxItem | null>(null);
  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const [widgets, widgetsHandler] = useListState<WidgetType>([]);
  const [initialWidgets, setInitialWidgets] = useState<WidgetType[]>([]);
  const [shouldUpdateWidgets, setShouldUpdateWidgets] =
    useState<boolean>(false);
  const [widgetEditIndex, setWidgetEditIndex] = useState<number | null>(null);

  const {
    data: tenantData,
    error: tenantError,
    isLoading: tenantIsLoading,
  } = useSWR(["fetchAllTenants"], () => fetchAllTenants(apiClient));

  const cacheKey = [
    "fetchTimeSeriesAndStructuralData",
    selectedTenant
  ];
  const {
    data: timeSeriesAndStructuralData,
    error: timeSeriesAndStructuralError,
    isLoading: timeSeriesAndStructuralIsLoading,
  } = useSWR(
    selectedTenant ? cacheKey : null,
    () => fetchTimeSeriesAndStructuralData(apiClient, selectedTenant),
    { shouldRetryOnError: false }
  );

  const handleChangeTenant = (tenant: string | null) => {
    setSelectedStructuralComponent(null);
    setSelectedTenant(tenant);
  };

  const handleUpdateWidgets = async () => {
    try {
      if (!selectedTenant) throw new Error("Tenant is required");
      if (!selectedStructuralComponent)
        throw new Error("Structural Component is required");

      const orderedWidgets = widgets.map((widget, index) => ({
        ...widget,
        order: index,
      }));

      setIsUpdating(true);
      await apiClient.widget.updateListTenantsTenantIdStructuralcomponentsComponentIdWidgetsPut(
        selectedTenant,
        selectedStructuralComponent.value,
        orderedWidgets
      );

      mutate(cacheKey);
      toast.success("Widgets updated successfully!");
    } catch (error) {
      toast.error(`Failed to update widgets: ${error}`);
    } finally {
      setTimeout(() => setIsUpdating(false), 2000);
    }
  };

  const handleUpdateSingleWidget = (widget: WidgetType) => {
    const cleanup = () => {
      setWidgetEditIndex(null);
      closeModal();
    };

    if (widgetEditIndex !== null) {
      widgetsHandler.setItem(widgetEditIndex, widget);
      setShouldUpdateWidgets(true);
      cleanup();
      return;
    }

    const isDuplicate = widgets.some((existingWidget) =>
      isEqual(existingWidget, widget)
    );
    if (isDuplicate) {
      toast.error("Duplicate widget detected. Widget not added.");
      cleanup();
      return;
    }

    widgetsHandler.prepend(widget);
    setShouldUpdateWidgets(true);
    cleanup();
  };

  useEffect(() => {
    if (shouldUpdateWidgets) {
      handleUpdateWidgets();
      setShouldUpdateWidgets(false);
    }
  }, [shouldUpdateWidgets, widgets]); // eslint-disable-line

  // Update widgets when a new structural component is selected
  useEffect(() => {
    if (!selectedStructuralComponent) return;
    if (!timeSeriesAndStructuralData) return;
    const structuralComponent =
      timeSeriesAndStructuralData.StructuralComponents?.find(
        (sc) => sc.identifier === selectedStructuralComponent.value
      );
    const timeseries = timeSeriesAndStructuralData.Timeseries;
    const widgetsData = structuralComponent?.tsa_widgets?.map((widget) => ({
      timeseries_identifier:
        timeseries?.find((ts) => ts.id === widget.timeseries.id)?.identifier ||
        widget.timeseries.id,
      label: widget.label,
      order: widget.order,
      unit: widget.unit,
      type: widget.type,
    }));
    if (!widgetsData) return;
    widgetsHandler.setState(widgetsData);
    setInitialWidgets([...widgetsData]);
  }, [selectedStructuralComponent, timeSeriesAndStructuralData]); // eslint-disable-line

  if (tenantError) return <p>Error: {tenantError.message}</p>;
  if (timeSeriesAndStructuralError)
    return <p>Error: {timeSeriesAndStructuralError.message}</p>;
  if (tenantIsLoading || !tenantData) return <Loader mt="lg" />;

  const tenantIdentifiers =
    tenantData?.map((tenant) => tenant.identifier) || [];
  const structuralComponents =
    timeSeriesAndStructuralData?.StructuralComponents?.map((sc) => ({
      value: sc.identifier,
      label: sc.label,
    })) || [];
  const timeseries = timeSeriesAndStructuralData?.Timeseries || [];

  return (
    <>
      <Paper withBorder p="md" radius="md">
        <Stack gap="md">
          <Select
            label="Tenant"
            placeholder="Select tenant"
            data={tenantIdentifiers}
            value={selectedTenant}
            onChange={handleChangeTenant}
          />
          {timeSeriesAndStructuralIsLoading ? (
            <Loader mt="lg" />
          ) : (
            structuralComponents.length > 0 && (
              <Select
                label="Structural Component"
                placeholder="Select structural component"
                data={structuralComponents}
                value={selectedStructuralComponent?.value}
                onChange={(_value, option) =>
                  setSelectedStructuralComponent(option)
                }
                searchable
              />
            )
          )}

          {selectedStructuralComponent && (
            <>
              <Text mt="lg" size="sm" fw={500}>
                Widgets for{" "}
                {selectedStructuralComponent?.label || "Structural Component"}
              </Text>

              <Group>
                {selectedStructuralComponent && (
                  <Button
                    onClick={() => {
                      setWidgetEditIndex(null);
                      openModal();
                    }}
                    variant="light">
                    Add New Widget
                  </Button>
                )}
              </Group>

              <DndWidgets
                widgets={widgets}
                widgetsHandler={widgetsHandler}
                editWidget={(index) => {
                  setWidgetEditIndex(index);
                  openModal();
                }}
              />

              <Group>
                <Button
                  onClick={handleUpdateWidgets}
                  disabled={
                    timeSeriesAndStructuralIsLoading ||
                    isEqual(widgets, initialWidgets)
                  }
                  loading={isUpdating}
                >
                  Save
                </Button>
              </Group>
            </>
          )}
        </Stack>
      </Paper>
      <Modal
        opened={openedModal}
        onClose={closeModal}
        title={`Add widget to ${selectedStructuralComponent?.label || "Structural Component"
          }`}
      >
        {selectedStructuralComponent && selectedTenant ? (
          <WidgetForm
            tenant_id={selectedTenant}
            timeseries={timeseries}
            initialWidget={
              widgetEditIndex !== null ? widgets[widgetEditIndex] : undefined
            }
            updateWidget={handleUpdateSingleWidget}
          />
        ) : (
          <Text>Select a tenant and structural component to add a widget</Text>
        )}
      </Modal>
    </>
  );
};

export default UpdateWidgets;
