import {
  ActionIcon,
  Checkbox,
  Group,
  Loader,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import {
  IconChevronDown,
  IconChevronRight,
  IconTrash,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useApiClient } from "../../ApiClientProvider";
import { SCWithStateAssignements, StateAssignment } from "../../generated";
import { AssignmentType, ComparisonOperatorEnum } from "../../types/state.type";
import DeleteAssignmentModal from "./DeleteAssignementModal";
import styles from "./StructuralComponentAssignment.module.css";

interface StructuralComponentAssignmentProps {
  tenantId: string;
  structuralData: SCWithStateAssignements;
  setStructuralData: React.Dispatch<
    React.SetStateAction<SCWithStateAssignements[]>
  >;
  selectedSCIdentifiers: string[];
  handleCheck: (identifier: string, isChecked: boolean) => void;
  onToggleExpand: () => void;
  isExpanded: boolean;
  hasChildren: boolean;
  isFetching: boolean;
}

const StructuralComponentAssignment: React.FC<
  StructuralComponentAssignmentProps
> = ({
  tenantId,
  structuralData,
  setStructuralData,
  selectedSCIdentifiers,
  handleCheck,
  onToggleExpand,
  isExpanded,
  hasChildren,
  isFetching,
}) => {
  const apiClient = useApiClient();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentAssignment, setCurrentAssignment] =
    useState<AssignmentType | null>(null);
  const [loading, setLoading] = useState(false);
  const [identifiersToUpdate, setIdentifiersToUpdate] = useState<string[]>([]);

  useEffect(() => {
    const updatedIdentifiers = [
      structuralData.component.identifier,
      ...selectedSCIdentifiers.filter(
        (identifier) => identifier !== structuralData.component.identifier,
      ),
    ];
    setIdentifiersToUpdate(updatedIdentifiers);
  }, [structuralData.component.identifier, selectedSCIdentifiers]);

  const handleDeleteClick = (assignment: AssignmentType) => {
    setCurrentAssignment(assignment);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async (identifiers: string[]) => {
    setLoading(true);
    setIsDeleteModalOpen(false);
    try {
      if (!currentAssignment) throw new Error("No current assignment");
      await apiClient.state.unassignStateTenantsTenantIdStatesStateIdUnassignDelete(
        tenantId,
        currentAssignment.stateIdentifier,
        currentAssignment.timeSeriesIdentifier,
        currentAssignment.operator,
        currentAssignment.value,
        identifiers,
      );
      setStructuralData((prev) =>
        prev.map((sc) => {
          if (identifiers.includes(sc.component.identifier)) {
            return {
              component: sc.component,
              assignments: sc.assignments.filter(
                (assignment) =>
                  assignment.signature !== currentAssignment.signature,
              ),
            };
          }
          return sc;
        }),
      );
      toast.success("Assignment deleted successfully");
    } catch (error) {
      toast.error("Failed to delete assignment");
    } finally {
      setCurrentAssignment(null);
      setLoading(false);
    }
  };

  const handleDeleteSingle = async () => {
    await handleDelete([structuralData.component.identifier]);
  };

  const handleDeleteSimilar = async () => {
    await handleDelete(identifiersToUpdate);
  };
  return (
    <>
      <Paper shadow="xs" radius="md" p="md" className={styles.paper}>
        <Stack>
          <Group>
            {hasChildren && (
              <ActionIcon
                onClick={onToggleExpand}
                variant="outline"
                color="gray"
                size="sm"
              >
                {isExpanded ? (
                  <IconChevronDown size={18} stroke={2} />
                ) : (
                  <IconChevronRight size={18} stroke={2} />
                )}
              </ActionIcon>
            )}
            <Checkbox
              checked={selectedSCIdentifiers.includes(
                structuralData.component.identifier,
              )}
              onChange={(event) =>
                handleCheck(
                  structuralData.component.identifier,
                  event.currentTarget.checked,
                )
              }
            />
            <Text size="md">{`${structuralData.component.identifier} (${structuralData.component.label})`}</Text>
          </Group>
          {loading || isFetching ? (
            <Loader type="dots" />
          ) : (
            <>
              {structuralData.assignments &&
                structuralData.assignments.length > 0 && (
                  <Stack>
                    {structuralData.assignments.map((assignment) => (
                      <Group key={assignment.signature}>
                        <ActionIcon
                          size="sm"
                          color="red"
                          variant="subtle"
                          aria-label="Remove"
                          onClick={() =>
                            handleDeleteClick(toAssignmentType(assignment))
                          }
                        >
                          <IconTrash
                            style={{
                              width: "70%",
                              height: "70%",
                            }}
                            stroke={1.5}
                          />
                        </ActionIcon>
                        {/* <ActionIcon
                            size="sm"
                            variant="filled"
                            aria-label="Remove"
                            onClick={() =>
                              handleEditClick(toAssignmentType(assignment))
                            }
                          >
                            <IconEdit
                              style={{
                                width: "70%",
                                height: "70%",
                              }}
                              stroke={1.5}
                            />
                          </ActionIcon> */}
                        <Text size="ms">{assignment.state_identifier}</Text>
                        <Text size="ms">
                          {assignment.timeseries_identifier}
                        </Text>
                        <Text size="ms">{assignment.operator}</Text>
                        <Text size="ms">{assignment.value}</Text>
                      </Group>
                    ))}
                  </Stack>
                )}
            </>
          )}
        </Stack>
      </Paper>
      {currentAssignment && (
        <DeleteAssignmentModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onDeleteSingle={handleDeleteSingle}
          onDeleteSimilar={handleDeleteSimilar}
          hasOtherSelectedComponents={identifiersToUpdate.length > 1}
          assignmentDetails={currentAssignment}
        />
      )}
    </>
  );
};

export default StructuralComponentAssignment;

const toAssignmentType = (assignment: StateAssignment): AssignmentType => {
  const operator = assignment.operator || ComparisonOperatorEnum.Equal;
  return {
    stateIdentifier: assignment.state_identifier,
    timeSeriesIdentifier: assignment.timeseries_identifier,
    operator: operator as ComparisonOperatorEnum,
    value: assignment.value,
    signature: assignment.signature,
  };
};
