import { Flex, Loader, Paper, Text, Title } from "@mantine/core";
import useSWR from "swr";

import { useApiClient } from "../../ApiClientProvider";
import { BackendClient } from "../../generated";
import useExcelStore from "../../hooks/useExcelStore";
import TenantTable from "../TenantTable";

const fetchTenant = async (apiClient: BackendClient, identifier: string | undefined) => {
  if (!identifier) {
    console.error("Identifier is required");
    return;
  }
  try {
    const response = await apiClient.tenant.getTenantsTenantIdGet(
      identifier,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const TenantInfo = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const tenant_identifier = sheetsData.Description?.tenant_identifier;

  const { data, error, isLoading } = useSWR(
    tenant_identifier ? ["tenant", tenant_identifier] : null,
    () => fetchTenant(apiClient, tenant_identifier),
    {
      shouldRetryOnError: false,
    }
  );
  const { edge_device_endpoints, ...tenantData } = data || {};

  if (!tenant_identifier) return null;

  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="column"
      >
        <Title order={2}>Tenant in Database</Title>
        {isLoading && (
          <>
            <Text>Loading tenant info...</Text>
            <Loader />
          </>
        )}
        {!isLoading && error && <Text> Tenant "{tenant_identifier}" not found in the database </Text>}
        {!isLoading && tenantData && <TenantTable tenant={tenantData} />}
      </Flex>
    </Paper>
  );
};

export default TenantInfo;
