/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenericResponse } from '../models/GenericResponse';
import type { WidgetType } from '../models/WidgetType';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WidgetService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Update List
     * Update widgets for a structural component.
     * @param tenantId
     * @param componentId
     * @param requestBody
     * @param xTenantId
     * @returns GenericResponse Successful Response
     * @throws ApiError
     */
    public updateListTenantsTenantIdStructuralcomponentsComponentIdWidgetsPut(
        tenantId: string,
        componentId: string,
        requestBody: Array<WidgetType>,
        xTenantId?: string,
    ): CancelablePromise<GenericResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/tenants/{tenant_id}/structuralcomponents/{component_id}/widgets',
            path: {
                'tenant_id': tenantId,
                'component_id': componentId,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
