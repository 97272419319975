import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { BackendClient } from "./generated";
import { AuthScope, useAuth } from "./hooks/useAuth";

interface ApiClientProviderProps {
  children: ReactNode;
}

const ApiClientContext = createContext<BackendClient | null>(null);

export const ApiClientProvider: React.FC<ApiClientProviderProps> = ({
  children,
}) => {
  const { acquireToken } = useAuth(AuthScope.BackendAPI);
  const [apiClient, setApiClient] = useState<BackendClient | null>(null);

  useEffect(() => {
    const updateToken = async () => {
      const token = await acquireToken();
      setApiClient(
        new BackendClient({
          BASE: process.env.REACT_APP_API_URL as string,
          HEADERS: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
    };

    updateToken();
  }, [acquireToken]);

  if (apiClient === null) {
    return null;
  }

  return (
    <ApiClientContext.Provider value={apiClient}>
      {children}
    </ApiClientContext.Provider>
  );
};

export const useApiClient = (): BackendClient => {
  const context = useContext(ApiClientContext);
  if (!context) {
    throw new Error("useApiClient must be used within an ApiClientProvider");
  }
  return context;
};
