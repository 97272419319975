import { Button, Modal, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import React, { useState } from 'react';

interface ConfirmationModalProps {
  confirmationList: string[];
  onConfirm: (confirmed: boolean) => void;
  openButtonLabel?: string;
  confirmLabel?: string;
  loading?: boolean;
  disabled?: boolean;
}

const ConfirmationButton: React.FC<ConfirmationModalProps> = ({
  confirmationList,
  onConfirm,
  openButtonLabel = "Open",
  confirmLabel = "Confirm",
  loading = false,
  disabled = false
}) => {
  const [opened, { open, close }] = useDisclosure(false);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleDelete = () => {
    if (parseInt(inputValue, 10) === confirmationList.length) {
      onConfirm(true);
      setInputValue('');
      close();
    } else {
      setError('Incorrect number of time series to delete.');
    }
  };

  const handleClose = () => {
    close();
    setError('');
    setInputValue('');
  }

  return (
    <>
      <Button
        onClick={open}
        loading={loading}
        disabled={disabled}
      >{openButtonLabel}
      </Button>
      <Modal opened={opened} onClose={handleClose} title={confirmLabel}>
        <Text>Are you sure you want to delete {confirmationList.length} time series? Please enter the number of time series to confirm deletion.</Text>
        <Text mt="md">Time series to delete:</Text>
        <Text mt="md">{confirmationList.join(', ')}</Text>
        <TextInput
          mt="md"
          placeholder="Number of time series"
          value={inputValue}
          onChange={(event) => setInputValue(event.currentTarget.value)}
          error={error}
          type="number"
        />
        <Button onClick={handleDelete} mt="md" color='red'>
          {confirmLabel}
        </Button>
      </Modal>
    </>
  );
};

export default ConfirmationButton;