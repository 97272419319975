import { useState } from "react";
import { baseUrl, pickerParams } from "../config/pickerConfig";
import combine from "../utils/combine";
import { useTokenFromCommand } from "./useTokenFromCommand";

const usePicker = () => {
  const [pickedFile, setPickedFile] = useState<any | null>(null);

  let win: Window | null = null;
  let port: MessagePort | null = null;

  const { getToken } = useTokenFromCommand();

  const launchPicker = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    win = window.open("", "Picker", "width=800,height=600");

    const authToken = await getToken({
      resource: baseUrl,
      command: "authenticate",
      type: "SharePoint",
    });

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify(pickerParams),
    });

    const url = combine(baseUrl, `_layouts/15/FilePicker.aspx?${queryString}`);

    const form = win!.document.createElement("form");

    form.setAttribute("action", url);
    form.setAttribute("method", "POST");

    // Create a hidden input element to send the OAuth token to the Picker.
    // This optional when using a popup window but required when using an iframe.
    const tokenInput = win!.document.createElement("input");
    tokenInput.setAttribute("type", "hidden");
    tokenInput.setAttribute("name", "access_token");
    tokenInput.setAttribute("value", authToken);
    form.appendChild(tokenInput);

    win!.document.body.append(form);

    form.submit();

    window.addEventListener("message", (event) => {
      if (event.source && event.source === win) {
        const message = event.data;

        if (
          message.type === "initialize" &&
          message.channelId === pickerParams.messaging.channelId
        ) {
          port = event.ports[0];
          port.addEventListener("message", messageListener);
          port.start();
          port.postMessage({ type: "activate" });
        }
      }
    });
  };

  const messageListener = async (message: MessageEvent) => {
    const payload = message.data;

    if (port == null) return;

    switch (payload.type) {
      case "notification":
        const notification = payload.data;

        if (notification.notification === "page-loaded") {
          // here we know that the picker page is loaded and ready for user interaction
        }

        break;

      case "command":
        // all commands must be acknowledged
        port.postMessage({
          type: "acknowledge",
          id: message.data.id,
        });

        // this is the actual command specific data from the message
        const command = payload.data;

        // command.command is the string name of the command
        switch (command.command) {
          case "authenticate":
            // the first command to handle is authenticate. This command will be issued any time the picker requires a token
            // 'getToken' represents a method that can take a command and return a valid auth token for the requested resource
            try {
              const token = await getToken(command);

              if (!token) {
                console.error(
                  `Could not get auth token for command: ${JSON.stringify(
                    command
                  )}`
                );
                return;
              }

              // we report a result for the authentication via the previously established port
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "token",
                  token: token,
                },
              });
            } catch (error) {
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "error",
                  error: {
                    code: "unableToObtainToken",
                    message: error,
                  },
                },
              });
            }

            break;
          case "close":
            win!.close();
            break;

          case "pick":
            setPickedFile(command);
            port!.postMessage({
              type: "result",
              id: payload.id,
              data: {
                result: "success",
              },
            });
            win!.close();
            break;

          default:
            // Always send a reply, if if that reply is that the command is not supported.
            port.postMessage({
              type: "result",
              id: message.data.id,
              data: {
                result: "error",
                error: {
                  code: "unsupportedCommand",
                  message: command.command,
                },
              },
            });

            break;
        }

        break;
    }
  };

  return {
    pickedFile,
    launchPicker,
  };
};

export default usePicker;
