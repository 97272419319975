import DifferencesInfo from "../../components/DifferencesInfo";
import EdgeDeviceInfo from "../../components/EdgeDeviceInfo";
import ParameterInfo from "../../components/ParameterInfo";
import PickerWrapped from "../../components/PickerWrapped";
import TenantInfo from "../../components/TenantInfo";
import TenantUsersInfo from "../../components/TenantUsersInfo";

const DifferencePage = () => {
  return (
    <>
      <PickerWrapped title="Difference: SharePoint and Database" />
      <DifferencesInfo />
      <TenantInfo />
      <TenantUsersInfo />
      <EdgeDeviceInfo />
      <ParameterInfo />
    </>
  );
};

export default DifferencePage;
