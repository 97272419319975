import { Table } from '@mantine/core';
import { EdgeDeviceEndpoint } from '../../generated';

interface EdgeEndpointsTableProps {
  endpoints: EdgeDeviceEndpoint[];
}

const EdgeEndpointsTable = ({ endpoints }: EdgeEndpointsTableProps) => {
  return (
    <Table stickyHeader striped>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Heartbeat Interval</Table.Th>
          <Table.Th>Active Endpoint</Table.Th>
          <Table.Th>Active Edge Device</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {endpoints.map((endpoint, index) => (
          <Table.Tr key={index}>
            <Table.Td>{endpoint.heartbeat_interval}</Table.Td>
            <Table.Td>{endpoint.active_endpoint}</Table.Td>
            <Table.Td>{endpoint.active_edgedevice}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default EdgeEndpointsTable;
