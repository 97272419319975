/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible Aggregation Methods
 */
export enum AggregationTypeEnum {
    MIN = 'MIN',
}
