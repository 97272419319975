/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible Tenant States
 */
export enum TenantState {
    ACTIVE = 'active',
    PAUSED = 'paused',
    INACTIVE = 'inactive',
}
