import { Box, Button, Center, Checkbox, Flex, Group, Loader, LoadingOverlay, Paper, ScrollArea, Select, Table, Text, TextInput, UnstyledButton, keys, rem } from "@mantine/core";
import '@mantine/dates/styles.css';
import { IconChevronDown, IconChevronUp, IconSearch, IconSelector } from '@tabler/icons-react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";
import { useApiClient } from "../../ApiClientProvider";
import { BackendClient, TSProcessorResponse } from "../../generated";
import useEnvironmentStore from "../../hooks/useEnvironmentStore";
import { EnumEnvironment } from "../../types/environment.type";
import combine from "../../utils/combine";
import MultiRangeDatePicker from "../MultiDateRangePicker";
import classes from './TableSort.module.css';

dayjs.extend(customParseFormat);

const fetchAlltenants = (apiClient: BackendClient) =>
  apiClient.tenant.getListTenantsGet();


const fetchLastTrainingTimes = (apiClient: BackendClient, tenant: string) => {
  if (!tenant) {
    return;
  }
  return apiClient.processor.getListTenantsTenantIdentifierTsprocessorsGet(tenant);
}

interface DateRange {
  start: Date;
  end: Date;
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

function filterData(data: TSProcessorResponse[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
  );
}

function sortData(
  data: TSProcessorResponse[],
  payload: { sortBy: keyof TSProcessorResponse | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
}

const PredictTenant = () => {
  const apiClient = useApiClient();
  const { environment } = useEnvironmentStore();
  const [selectedTenant, setSelectedTenant] = useState<string | null>('');
  const [isTrainingLoading, setIsTrainingLoading] = useState<boolean>(false);
  const { data, error, isLoading } = useSWR(["fetchAlltenants"], () => fetchAlltenants(apiClient));

  const [ranges, setRanges] = useState<DateRange[]>([]);

  const [modelSelection, setModelSelection] = useState([] as string[]);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<keyof TSProcessorResponse | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const cache_key = ["fetchLastTrainingTimes", selectedTenant];
  const { data: modelData, isLoading: modelDataIsLoading } = useSWR(cache_key, () => fetchLastTrainingTimes(apiClient, selectedTenant || ''));
  const [sortedData, setSortedData] = useState(modelData);

  const handlePredictModels = async () => {
    if (!ranges[0]) {
      toast.warning("Please select at least one range");
      return;
    }
    if (modelSelection.length < 1) {
      toast.warning("Please select at least one model to predict");
      return;
    }
    if (environment !== EnumEnvironment.Production && environment !== EnumEnvironment.Staging) {
      toast.warning(`Predict API cannot be used in ${environment} environment`);
      return;
    }

    if (!window.confirm(`Are you sure you want to predict ${modelSelection.length} models for ${selectedTenant}?`)) return;

    const baseUrl = process.env.REACT_APP_MODEL_API_URL;
    if (!baseUrl) {
      toast.error("Error: REACT_APP_MODEL_API_URL is not set");
      return;
    }
    const url = combine(baseUrl, "model/predict");
    const body = JSON.stringify({
      id_item: modelSelection,
      start_time: ranges.map(range => dayjs(range.start).format("YYYY-MM-DD HH:mm:ss")),
      end_time: ranges.map(range => dayjs(range.end).format("YYYY-MM-DD HH:mm:ss")),
    });

    try {
      setIsTrainingLoading(true);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: body
      });

      if (response.ok) {
        toast.success(`Request successfully sent to predict ${modelSelection.length} models for ${selectedTenant}`);
      } else {
        toast.error(`Error: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(`Error: ${error}`);
    }
    setIsTrainingLoading(false);
  };

  const toggleRow = (id: string) =>
    setModelSelection((current) =>
      current.includes(id) ? current.filter((item) => item !== id) : [...current, id]
    );
  const toggleAll = () => {
    if (!sortedData) return;
    setModelSelection((current) => (current.length === sortedData.length ? [] : sortedData.map((model) => model.id)));
  };
  const setSorting = (field: keyof TSProcessorResponse) => {
    if (!modelData) return;
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(modelData, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!modelData) return;
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(sortData(modelData, { sortBy, reversed: reverseSortDirection, search: value }));
  };
  useEffect(() => {
    if (modelData) {
      setSortedData(sortData(modelData, { sortBy, reversed: reverseSortDirection, search }));
    }
  }, [modelData, sortBy, reverseSortDirection, search]);

  if (error) return <p>Error: {error.message}</p>;
  if (isLoading || !data) return <Loader mt="lg" />;
  const labels = data.map((tenant) => tenant.identifier || "");

  const trainingTable = sortedData?.map((model) => {
    return (
      <Table.Tr key={model.id}>
        <Table.Td>
          <Checkbox checked={modelSelection.includes(model.id)} onChange={() => toggleRow(model.id)} />
        </Table.Td>
        <Table.Td>{model.identifier}</Table.Td>
        <Table.Td>{model.last_training || "-"}</Table.Td>
        <Table.Td>{model.exclusion_start_dates || "-"}</Table.Td>
        <Table.Td>{model.exclusion_end_dates || "-"}</Table.Td>
      </Table.Tr>
    )
  });

  return (
    <>
      <Paper withBorder p="md" radius="md">
        <ScrollArea>
          <Select
            label="Select Tenant"
            placeholder="Pick tenant"
            data={labels}
            value={selectedTenant}
            onChange={setSelectedTenant}
          />

          <MultiRangeDatePicker
            value={ranges}
            onChange={setRanges}
          />

          {selectedTenant && sortedData != null &&
            <Box pos="relative">
              <LoadingOverlay visible={modelDataIsLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
              <TextInput
                label="Search and select models"
                placeholder="Search by any field"
                mt="md"
                leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                value={search}
                onChange={handleSearchChange}
              />
              <Table stickyHeader striped>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Th style={{ width: rem(50) }}>
                      <Checkbox
                        onChange={toggleAll}
                        checked={modelSelection.length === data.length}
                        indeterminate={modelSelection.length > 0 && modelSelection.length !== data.length}
                      />
                    </Table.Th>
                    <Th
                      sorted={sortBy === 'identifier'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('identifier')}
                    >
                      Identifier
                    </Th>
                    <Th
                      sorted={sortBy === 'last_training'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('last_training')}
                    >
                      Last Training
                    </Th>
                    <Th
                      sorted={sortBy === 'exclusion_start_dates'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('exclusion_start_dates')}
                    >
                      Exclusion Start Dates
                    </Th>
                    <Th
                      sorted={sortBy === 'exclusion_end_dates'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('exclusion_end_dates')}
                    >
                      Exclusion End Dates
                    </Th>
                  </Table.Tr>
                </Table.Tbody>
                <Table.Tbody>{trainingTable}</Table.Tbody>
              </Table>
            </Box>
          }
        </ScrollArea>

        <Flex
          mt="xl"
          gap="md"
          align="flex-start"
          direction="column"
        >
          {modelDataIsLoading && sortedData == null && <Loader />}
          <Button
            color="green"
            variant="light"
            disabled={!(selectedTenant && modelSelection.length)}
            onClick={handlePredictModels}
            loading={isTrainingLoading}
          >
            {selectedTenant && modelSelection.length ? `Predict ${modelSelection.length} Model${modelSelection.length > 1 ? "s" : ""}` : "Select Tenant and Models"}
          </Button>
        </Flex>
      </Paper>
    </>
  );
};

export default PredictTenant;
