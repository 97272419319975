import { Paper, Title, Text, Container, Stack } from "@mantine/core";
import SignInButton from "../SignInButton";
import classes from "./AuthenticationTitle.module.css";

function AuthenticationTitle() {
  return (
    <Container size={340} my={120} style={{ textAlign: "center" }}>
      <Paper withBorder shadow="md" p={40} mt={40} radius="md">
        <Stack gap="xl">
          <Title ta="center" className={classes.title}>
            AI-Omatic Dashboard
          </Title>
          <Text c="dimmed" size="sm" ta="center">
            Access restricted to AI-Omatic team members
          </Text>
          <SignInButton />
        </Stack>
      </Paper>
    </Container>
  );
}

export default AuthenticationTitle;
