import { Button } from "@mantine/core";
import useExcel from "../../hooks/useExcel";
import useExcelStore from "../../hooks/useExcelStore";

const PickerRefreshButton = ({ children }: { children: React.ReactNode }) => {
  const { pickedFile } = useExcelStore();
  const { refreshData } = useExcel(pickedFile);

  const handleClick = () => {
    if (!window.confirm("Changes in Excel can take up to 5 minutes to be saved in SharePoint if you don't close the file.")) return;
    refreshData();
  }

  return <Button variant="outline" onClick={handleClick}>{children}</Button>;
};

export default PickerRefreshButton;
