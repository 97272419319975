import { useMsal } from "@azure/msal-react";
import combine from "../utils/combine";

export const useTokenFromCommand = () => {
  const { instance } = useMsal();

  async function getToken(command: any) {
    let accessToken = "";
    let authParams = null;

    switch (command.type) {
      case "SharePoint":
      case "SharePoint_SelfIssued":
        authParams = { scopes: [`${combine(command.resource, ".default")}`] };
        break;
      default:
        break;
    }

    try {
      // see if we have already the idtoken saved
      const resp = await instance.acquireTokenSilent(authParams!);

      // const resp = await instance.acquireTokenSilent(authParams!);
      accessToken = resp.accessToken;
    } catch (e) {
      // per examples we fall back to popup
      const resp = await instance.loginPopup(authParams!);
      instance.setActiveAccount(resp.account);

      if (resp.idToken) {
        const resp2 = await instance.acquireTokenSilent(authParams!);
        accessToken = resp2.accessToken;
      } else {
        // throw the error that brought us here
        throw e;
      }
    }

    return accessToken;
  }

  return { getToken };
};
