import { Paper, Text, Title } from "@mantine/core";
import TrainTenant from "../../components/TrainTenant";

const TrainPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">
        Train Models
      </Title>
      <Text mb="xl">
        Send a training request to the selected tenant
      </Text>
      <TrainTenant />
    </Paper>
  );
};

export default TrainPage;
