import { Paper, Text, Title } from "@mantine/core";
import UpdateDashboardLabel from "../../components/UpdateDashboardLabel";

const LabelPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">Update Dashboard Label</Title>
      <Text mb="xl">
        Update the dashboard label that appears in the upper left corner of the dashboard
      </Text>
      <UpdateDashboardLabel />
    </Paper>
  );
};

export default LabelPage;
