/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenericResponse } from '../models/GenericResponse';
import type { RuleProcessorResponse } from '../models/RuleProcessorResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class RuleService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get One Rule
     * Get specific rule for a tenant.
     *
     * This function returns one RuleProcessor for a specific tenant.
     *
     * Args:
     * tenant_id (str): The tenant identifier.
     * rule_id (str): The rule identifier.
     * client (RestClient): The warehouse client.
     *
     * Raises:
     * fastapi.HTTPException: Raised if the tenant is not found.
     *
     * Returns:
     * list[RuleProcessorResponse]: The requested rule for a specific tenant.
     * @param tenantId
     * @param ruleId
     * @param xTenantId
     * @returns RuleProcessorResponse Successful Response
     * @throws ApiError
     */
    public getOneRuleTenantsTenantIdRulesRuleIdGet(
        tenantId: string,
        ruleId: string,
        xTenantId?: string,
    ): CancelablePromise<RuleProcessorResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tenants/{tenant_id}/rules/{rule_id}',
            path: {
                'tenant_id': tenantId,
                'rule_id': ruleId,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upsert Rule
     * Create or update rule for a tenant.
     * @param tenantId
     * @param ruleId
     * @param timeseriesIds List of input timeseries identifiers for rule string.
     * @param operators List of operators (<, <=, ==, =>, >) for rule string.
     * @param thresholds List of threshold values for rule string.
     * @param booleans List of boolean operaters (AND, OR) for rule string.
     * @param xTenantId
     * @returns GenericResponse Successful Response
     * @throws ApiError
     */
    public upsertRuleTenantsTenantIdRulesRuleIdPut(
        tenantId: string,
        ruleId: string,
        timeseriesIds: Array<string>,
        operators: Array<string>,
        thresholds: Array<string>,
        booleans?: Array<string>,
        xTenantId?: string,
    ): CancelablePromise<GenericResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/tenants/{tenant_id}/rules/{rule_id}',
            path: {
                'tenant_id': tenantId,
                'rule_id': ruleId,
            },
            query: {
                'timeseries_ids': timeseriesIds,
                'operators': operators,
                'thresholds': thresholds,
                'booleans': booleans,
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Rule
     * Delete rule for a tenant.
     * @param tenantId
     * @param ruleId
     * @param xTenantId
     * @returns GenericResponse Successful Response
     * @throws ApiError
     */
    public deleteRuleTenantsTenantIdRulesRuleIdDelete(
        tenantId: string,
        ruleId: string,
        xTenantId?: string,
    ): CancelablePromise<GenericResponse> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/tenants/{tenant_id}/rules/{rule_id}',
            path: {
                'tenant_id': tenantId,
                'rule_id': ruleId,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get List
     * Get list of rules for a tenant.
     *
     * This function returns all RuleProcessors for a specific tenant.
     *
     * Args:
     * tenant_id (str): The tenant identifier.
     * client (RestClient): The warehouse client.
     *
     * Raises:
     * fastapi.HTTPException: Raised if the tenant is not found.
     *
     * Returns:
     * list[RuleProcessorResponse]: The rules for a specific tenant.
     * @param tenantId
     * @param xTenantId
     * @returns RuleProcessorResponse Successful Response
     * @throws ApiError
     */
    public getListTenantsTenantIdRulesGet(
        tenantId: string,
        xTenantId?: string,
    ): CancelablePromise<Array<RuleProcessorResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tenants/{tenant_id}/rules',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
