import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { ActionIcon, rem, Stack, Text } from "@mantine/core";
import { UseListStateHandlers } from "@mantine/hooks";
import { IconEdit, IconGripVertical, IconTrash } from "@tabler/icons-react";
import cx from "clsx";
import { WidgetType } from "../../generated";
import classes from "./DndWidgets.module.css";

export default function DndWidgets({
  widgets,
  widgetsHandler,
  editWidget,
}: {
  widgets: WidgetType[];
  widgetsHandler: UseListStateHandlers<WidgetType>;
  editWidget: (index: number) => void;
}) {
  const items = widgets.map((item, index) => {
    const uniqueId = `${item.timeseries_identifier}-${item.label}-${item.type}-${item.unit}`;
    return (
      <Draggable key={uniqueId} index={index} draggableId={uniqueId}>
        {(provided, snapshot) => (
          <div
            className={cx(classes.item, {
              [classes.itemDragging]: snapshot.isDragging,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div {...provided.dragHandleProps} className={classes.dragHandle}>
              <IconGripVertical
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </div>

            <div className={classes.itemContent}>
              <div>
                <Text>{item.label}</Text>
                <Text c="dimmed" size="sm">
                  {item.timeseries_identifier}
                </Text>
                <Text c="dimmed" size="sm">
                  Order: {index + 1} • Type: {item.type || "-"} • Unit:{" "}
                  {item.unit || "-"}
                </Text>
              </div>

              <Stack>
                <ActionIcon
                  variant="light"
                  size="sm"
                  color="gray"
                  onClick={() => editWidget(index)}
                >
                  <IconEdit
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
                <ActionIcon
                  variant="light"
                  size="sm"
                  color="red"
                  onClick={() => widgetsHandler.remove(index)}
                >
                  <IconTrash
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Stack>
            </div>
          </div>
        )}
      </Draggable>
    );
  });

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) =>
        widgetsHandler.reorder({
          from: source.index,
          to: destination?.index || 0,
        })
      }
    >
      <Droppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
