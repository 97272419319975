/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { aiomatic_warehouse__models__entity__tenant__Tenant__Reference } from './aiomatic_warehouse__models__entity__tenant__Tenant__Reference';

export type aiomatic_warehouse__models__entity__timeseries__TimeSeries__Reference = {
    basetype?: aiomatic_warehouse__models__entity__timeseries__TimeSeries__Reference.basetype;
    id: string;
    tenant: aiomatic_warehouse__models__entity__tenant__Tenant__Reference;
};

export namespace aiomatic_warehouse__models__entity__timeseries__TimeSeries__Reference {

    export enum basetype {
        TIME_SERIES = 'TimeSeries',
    }


}

