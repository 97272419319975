import { Table } from '@mantine/core';
import { Tenant } from '../../generated';

interface TenantTableProps {
  tenant: Tenant | {};
}

const TenantTable = ({ tenant }: TenantTableProps) => {
  return (
    <Table stickyHeader striped>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Property</Table.Th>
          <Table.Th>Value</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {Object.entries(tenant).map(([key, value]) => (
          <Table.Tr key={key}>
            <Table.Td>{key}</Table.Td>
            <Table.Td>{typeof value === 'object' ? JSON.stringify(value, null, 2) : value.toString()}</Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  );
};

export default TenantTable;
