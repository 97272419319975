import { Paper, Text, Title } from "@mantine/core";
import UpdateRuleProcessor from "../../components/UpdateRuleProcessor";

const RulePage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">Manage Rules</Title>
      <Text mb="xl">
        Define and update rules
      </Text>
      <UpdateRuleProcessor />
    </Paper>
  );
};

export default RulePage;
