import { Flex, Paper, Title } from "@mantine/core";
import Picker from "../Picker";

interface PickerWrappedProps {
  title: string;
}

const PickerWrapped = ({ title }: PickerWrappedProps) => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Flex
        mih={50}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="column"
      >
        <Title order={2}>{title}</Title>
        <Picker />
      </Flex>
    </Paper>
  );
};

export default PickerWrapped;
