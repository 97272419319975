/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_update_exclusions_tenants__tenant_identifier__exclusions_put } from '../models/Body_update_exclusions_tenants__tenant_identifier__exclusions_put';
import type { SummaryResponse } from '../models/SummaryResponse';
import type { TSProcessorResponse } from '../models/TSProcessorResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProcessorService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get List
     * Fetch the list of TS processors for a tenant.
     * @param tenantIdentifier
     * @param xTenantId
     * @returns TSProcessorResponse Successful Response
     * @throws ApiError
     */
    public getListTenantsTenantIdentifierTsprocessorsGet(
        tenantIdentifier: string,
        xTenantId?: string,
    ): CancelablePromise<Array<TSProcessorResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tenants/{tenant_identifier}/tsprocessors',
            path: {
                'tenant_identifier': tenantIdentifier,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Exclusions
     * Update the exclusion periods for a tenant.
     * @param tenantIdentifier
     * @param requestBody
     * @param xTenantId
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateExclusionsTenantsTenantIdentifierExclusionsPut(
        tenantIdentifier: string,
        requestBody: Body_update_exclusions_tenants__tenant_identifier__exclusions_put,
        xTenantId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/tenants/{tenant_identifier}/exclusions',
            path: {
                'tenant_identifier': tenantIdentifier,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Statistics
     * Get statistics about the TS processors.
     * @param xTenantId
     * @returns SummaryResponse Successful Response
     * @throws ApiError
     */
    public getStatisticsStatisticsGet(
        xTenantId?: string,
    ): CancelablePromise<SummaryResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/statistics',
            query: {
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
