import { Button, Group, Text } from "@mantine/core";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";
import { useApiClient } from "../../ApiClientProvider";
import {
  BackendClient,
  EntityEnum,
  SCWithStateAssignements,
} from "../../generated";
import { AssignmentType } from "../../types/state.type";
import StructuralComponentAssignment from "../StructuralComponentAssignment";
import CreateUpdateAssignmentModal from "../StructuralComponentAssignment/CreateUpdateAssignmentModal";

interface StructuralComponentTreeProps {
  tenantId: string;
  structuralData: SCWithStateAssignements[];
  setStructuralData: React.Dispatch<
    React.SetStateAction<SCWithStateAssignements[]>
  >;
  selectedIdentifiers: string[];
  setSelectedIdentifiers: React.Dispatch<React.SetStateAction<string[]>>;
  isFetching: boolean;
}

const StructuralComponentTree: React.FC<StructuralComponentTreeProps> = ({
  tenantId,
  structuralData,
  setStructuralData,
  selectedIdentifiers,
  setSelectedIdentifiers,
  isFetching,
}) => {
  const apiClient = useApiClient();
  const [allTimeSeriesIds, setAllTimeSeriesIds] = useState<string[]>([]);
  const [allStateIds, setAllStateIds] = useState<string[]>([]);
  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [isCreateUpdateModalOpen, setIsCreateUpdateModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(
    [tenantId, "fetchTimeSeriesAndStateTypes"],
    () => fetchTimeSeriesAndStateTypes(apiClient, tenantId),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    },
  );

  useEffect(() => {
    if (data) {
      const timeSeriesIds = data.Timeseries?.map((entity) => entity.identifier);
      const stateIds = data.StateTypes?.map((entity) => entity.identifier);
      setAllTimeSeriesIds(timeSeriesIds || []);
      setAllStateIds(stateIds || []);
    }
  }, [data]);

  const handleCheck = useCallback(
    (identifier: string, isChecked: boolean) => {
      setSelectedIdentifiers((prev) => {
        const newSet = new Set(prev);
        if (isChecked) {
          newSet.add(identifier);
        } else {
          newSet.delete(identifier);
        }
        return Array.from(newSet);
      });
    },
    [setSelectedIdentifiers],
  );

  const handleToggleExpand = (id: string) => {
    setExpandedNodes((prev) =>
      prev.includes(id)
        ? prev.filter((nodeId) => nodeId !== id)
        : [...prev, id],
    );
  };

  const selectDescendants = () => {
    const allDescendants = new Set<string>(selectedIdentifiers);
    const collectDescendants = (parentId: string) => {
      const children = parentChildMap.get(parentId) || [];
      children.forEach((child) => {
        allDescendants.add(child.component.identifier);
        collectDescendants(child.component.id);
      });
    };
    selectedIdentifiers.forEach((identifier) => {
      const component = structuralData.find(
        (sc) => sc.component.identifier === identifier,
      );
      if (component) {
        collectDescendants(component.component.id);
      }
    });
    setSelectedIdentifiers(Array.from(allDescendants));
  };

  const parentChildMap = buildParentChildMap(structuralData);

  const renderStructuralComponents = (
    components: SCWithStateAssignements[],
    depth: number = 0,
  ) => {
    return components.map((sc) => {
      const isExpanded = expandedNodes.includes(sc.component.id);
      const children = parentChildMap.get(sc.component.id) || [];

      return (
        <div
          key={sc.component.id}
          style={{ marginLeft: depth * 20, marginTop: 20 }}
        >
          <StructuralComponentAssignment
            tenantId={tenantId}
            structuralData={sc}
            setStructuralData={setStructuralData}
            selectedSCIdentifiers={selectedIdentifiers}
            handleCheck={handleCheck}
            onToggleExpand={() => handleToggleExpand(sc.component.id)}
            isExpanded={isExpanded}
            hasChildren={children.length > 0}
            isFetching={isFetching}
          />
          {isExpanded &&
            children.length > 0 &&
            renderStructuralComponents(children, depth + 1)}
        </div>
      );
    });
  };

  const handleAddAssignmentClick = () => {
    if (!allTimeSeriesIds.length || !allStateIds.length) {
      toast.warning("No Time Series or State Types available");
      return;
    }
    setIsCreateUpdateModalOpen(true);
  };

  const handleSaveAssignment = async (assignment: AssignmentType) => {
    setLoading(true);
    setIsCreateUpdateModalOpen(false);
    try {
      if (!assignment) throw new Error("No assignment provided");
      await apiClient.state.assignStateTenantsTenantIdStatesStateIdAssignPost(
        tenantId,
        assignment.stateIdentifier,
        assignment.timeSeriesIdentifier,
        assignment.operator,
        assignment.value,
        selectedIdentifiers,
      );
      setStructuralData((prev) =>
        prev.map((sc) => {
          if (selectedIdentifiers.includes(sc.component.identifier)) {
            return {
              ...sc,
              assignments: [
                ...(sc.assignments || []),
                {
                  state_identifier: assignment.stateIdentifier,
                  timeseries_identifier: assignment.timeSeriesIdentifier,
                  operator: assignment.operator as any,
                  value: assignment.value,
                  signature: assignment.signature || "",
                },
              ],
            };
          }
          return sc;
        }),
      );
      toast.success("Assignment saved successfully");
    } catch (error) {
      toast.error("Failed to save assignment");
    } finally {
      setLoading(false);
    }
  };

  if (error) return <p>Error: {error.message}</p>;

  const topLevelComponents = structuralData.filter(
    (sc) => !sc.component.parent,
  );

  return (
    <>
      <Text size="sm" fw={500} mb="xs">
        Structural Components
      </Text>
      <Group>
        <Button
          onClick={() =>
            setSelectedIdentifiers(
              structuralData.map((sc) => sc.component.identifier),
            )
          }
          disabled={!structuralData.length}
        >
          Select All
        </Button>
        <Button
          onClick={selectDescendants}
          disabled={
            selectedIdentifiers.length === 0 ||
            selectedIdentifiers.length === structuralData.length
          }
          variant="outline"
        >
          Select Descendants
        </Button>
        <Button
          onClick={() => setSelectedIdentifiers([])}
          disabled={!selectedIdentifiers.length}
          variant="outline"
          color="orange"
        >
          Clear Selection
        </Button>
      </Group>

      {renderStructuralComponents(topLevelComponents)}

      <Group mt="lg">
        <Button
          onClick={() => handleAddAssignmentClick()}
          disabled={!selectedIdentifiers.length}
          loading={loading}
          variant="outline"
          color="orange"
        >
          {selectedIdentifiers.length <= 1
            ? "Add Assignment"
            : `Add Assignments (${selectedIdentifiers.length})`}
        </Button>
      </Group>
      <CreateUpdateAssignmentModal
        isOpen={isCreateUpdateModalOpen}
        onClose={() => setIsCreateUpdateModalOpen(false)}
        onSaveSimilar={handleSaveAssignment}
        allTimeSeriesIds={allTimeSeriesIds}
        allStateIds={allStateIds}
      />
    </>
  );
};

export default StructuralComponentTree;

function buildParentChildMap(structuralComponents: SCWithStateAssignements[]) {
  const map = new Map<string, SCWithStateAssignements[]>();

  structuralComponents.forEach((sc) => {
    const parentId = sc.component.parent?.id;
    if (parentId) {
      if (!map.has(parentId)) {
        map.set(parentId, []);
      }
      map.get(parentId)!.push(sc);
    }
  });

  return map;
}

const fetchTimeSeriesAndStateTypes = (
  apiClient: BackendClient,
  tenant: string | null,
) => {
  if (!tenant) return;
  return apiClient.entities.getEntitiesTenantsTenantIdEntitiesGet(tenant, [
    EntityEnum.TIME_SERIES,
    EntityEnum.STATE_TYPE,
  ]);
};
