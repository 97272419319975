import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { BrowserRouter } from "react-router-dom";

import { ApiClientProvider } from "./ApiClientProvider";
import AuthenticationTitle from "./components/AuthenticationTitle";
import Router from "./pages/router";

function App() {
  return (
    <>
      <AuthenticatedTemplate>
        <BrowserRouter>
          <ApiClientProvider>
            <Router />
          </ApiClientProvider>
        </BrowserRouter>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticationTitle />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
