import { Button, Loader, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import { useApiClient } from "../../ApiClientProvider";
import { BackendClient } from "../../generated";
import useEnvironmentStore from "../../hooks/useEnvironmentStore";
import useExcelStore from "../../hooks/useExcelStore";

const fetchTenant = async (apiClient: BackendClient, identifier: string | undefined) => {
  if (!identifier) {
    console.error("Identifier is required");
    return;
  }
  return await apiClient.tenant.getTenantsTenantIdGet(
    identifier
  );
};

const CreateTenant = () => {
  const apiClient = useApiClient();
  const { sheetsData } = useExcelStore();
  const [isUpdating, setIsUpdating] = useState(false);
  const tenant_identifier = sheetsData.Description?.tenant_identifier;
  const tenant_label = sheetsData.Description?.tenant_label;
  const dashboard_label = sheetsData.Description?.dashboard_label;
  const tenant_resolution = sheetsData.Description?.tenant_resolution;

  const { data, error, isLoading } = useSWR(
    tenant_identifier ? ["tenant", tenant_identifier] : null,
    () => fetchTenant(apiClient, tenant_identifier),
    {
      shouldRetryOnError: false,
    }
  );

  const createTenant = async () => {
    try {
      if (!tenant_identifier) throw new Error("Tenant identifier is required");
      if (!tenant_label) throw new Error("Tenant label is required");
      if (!tenant_resolution) throw new Error("Tenant resolution is required");
      if (!dashboard_label) throw new Error("Dashboard label is required");
      setIsUpdating(true);
      await apiClient.tenant.createTenantsTenantIdPost(
        tenant_identifier,
        tenant_label,
        dashboard_label,
        tenant_resolution,
      );
      toast.success("Tenant created successfully!");
      mutate(["tenant", tenant_identifier]);
    } catch (error) {
      toast.error(`Failed to update tenant information: ${error}`);
    } finally {
      setIsUpdating(false);
    }
  };

  const isNotFound = error?.status === 404;
  const isOtherError = error?.status !== 404 && error;
  const isValid = tenant_identifier === data?.identifier;

  const { setMaxActiveStep } = useEnvironmentStore();
  useEffect(() => {
    setMaxActiveStep(isValid);
  }, [isValid, setMaxActiveStep]);

  if (isLoading) {
    return (
      <>
        <Text>Loading tenant information...</Text>
        <Loader />
      </>
    );
  }

  return (
    <>
      {isValid && (
        <Text>Found tenant <b>{tenant_identifier}</b> in the database</Text>
      )}
      {isNotFound && (
        <>
          <Text>
            Tenant <b>{tenant_identifier}</b> not found in the database.
          </Text>
          <Button onClick={createTenant} loading={isUpdating}>
            Create Tenant
          </Button>
        </>
      )}
      {isOtherError && (
        <Text>
          Failed to fetch tenant information: {error?.message}
        </Text>
      )}
    </>
  );
};

export default CreateTenant;
