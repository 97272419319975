import { routerType } from "../types/router.types";
import ClassifierPage from "./Classifier";
import DifferencePage from "./Difference";
import ExcelUpdatePage from "./ExcelUpdate";
import ExclusionPage from "./Exclusion";
import LabelPage from "./Label";
import PredictPage from "./Predict";
import RulePage from "./Rule";
import StatePage from "./State";
import StateAssignmentPage from "./StateAssignment";
import SummarydPage from "./Summary";
import TrainPage from "./Train";
import WidgetPage from "./Widget";

const pagesData: routerType[] = [
  {
    key: "difference",
    path: "/",
    element: <DifferencePage />,
  },
  {
    key: "rebuild",
    path: "/rebuild",
    element: <ExcelUpdatePage />,
  },
  {
    key: "label",
    path: "/label",
    element: <LabelPage />,
  },
  {
    key: "widget",
    path: "/widget",
    element: <WidgetPage />,
  },
  {
    key: "state",
    path: "/state",
    element: <StatePage />,
  },
  {
    key: "rule",
    path: "/state/rule",
    element: <RulePage />,
  },
  {
    key: "rule",
    path: "/state/assignement",
    element: <StateAssignmentPage />,
  },
  {
    key: "model",
    path: "/model",
    element: <SummarydPage />,
  },
  {
    key: "exclusion",
    path: "/model/exclusion",
    element: <ExclusionPage />,
  },
  {
    key: "train",
    path: "/model/train",
    element: <TrainPage />,
  },
  {
    key: "predict",
    path: "/model/predict",
    element: <PredictPage />,
  },
  {
    key: "Tools",
    path: "/classifier",
    element: <ClassifierPage />,
  }
];

export default pagesData;
