import { Flex, Table } from "@mantine/core";
import { utils } from "xlsx";
import useExcelStore from "../../hooks/useExcelStore";

const SheetStats = () => {
  const { workbook } = useExcelStore();
  if (!workbook) return null;

  const sheetData = workbook.SheetNames.map((sheetName) => {
    const worksheet = workbook.Sheets[sheetName];
    const raw_data = utils.sheet_to_json(worksheet, { header: 1 });
    const nonEmptyRows = raw_data.filter(
      (arr) => Array.isArray(arr) && arr.length > 0
    );
    return {
      name: sheetName,
      rowCount: nonEmptyRows.length - 1,
    };
  });

  const filteredSheetData = sheetData.filter((sheet) =>
    ["parameter", "edge", "model", "part", "hierarchy"].includes(
      sheet.name.trim().toLowerCase()
    )
  );
  return (
    <Flex>
      <Table stickyHeader striped>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Sheet Name</Table.Th>
            <Table.Th>Number of Elements</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {filteredSheetData.map((sheet) => (
            <Table.Tr key={sheet.name}>
              <Table.Td>{sheet.name}</Table.Td>
              <Table.Td>{sheet.rowCount}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Flex>
  );
};

export default SheetStats;
