import { Button, Group, Modal, Text } from "@mantine/core";
import React from "react";
import { AssignmentType } from "../../types/state.type";

interface DeleteAssignmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteSingle: () => void;
  onDeleteSimilar: () => void;
  assignmentDetails: AssignmentType;
  hasOtherSelectedComponents: boolean;
}

const DeleteAssignmentModal: React.FC<DeleteAssignmentModalProps> = ({
  isOpen,
  onClose,
  onDeleteSingle,
  onDeleteSimilar,
  assignmentDetails,
  hasOtherSelectedComponents,
}) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title="Delete Assignment Confirmation"
    >
      <Text mb="sm">
        Do you want to delete this assignment with the following details?
      </Text>
      <Text size="sm">
        <strong>State Identifier:</strong> {assignmentDetails.stateIdentifier}
      </Text>
      <Text size="sm">
        <strong>Timeseries Identifier:</strong>{" "}
        {assignmentDetails.timeSeriesIdentifier}
      </Text>
      <Text size="sm">
        <strong>Operator:</strong> {assignmentDetails.operator}
      </Text>
      <Text size="sm">
        <strong>Value:</strong> {assignmentDetails.value}
      </Text>

      <Group mt="md">
        <Button variant="light" color="red" onClick={onDeleteSingle}>
          {hasOtherSelectedComponents ? "Delete Only This" : "Delete"}
        </Button>

        {hasOtherSelectedComponents && (
          <Button color="red" onClick={onDeleteSimilar}>
            Delete Similar in Selected Components
          </Button>
        )}
      </Group>
    </Modal>
  );
};

export default DeleteAssignmentModal;
