import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mantine/core";

const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = async () => {
    try {
      await instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } catch (error) {
      console.error("Popup logout failed. Falling back to redirect.", error);
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return <Button onClick={handleLogout}>Sign Out</Button>;
};

export default SignOutButton;
