import { Paper, Text, Title } from "@mantine/core";
import PredictTenant from "../../components/PredictTenant";

const PredictPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">
        Predict Models
      </Title>
      <Text mb="xl">
        Send a predict request to the selected tenant
      </Text>
      <PredictTenant />
    </Paper>
  );
};

export default PredictPage;
