import { Box, Button, Group, Select, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { toast } from "react-toastify";
import { useApiClient } from "../../ApiClientProvider";
import { BackendClient, TimeSeries, WidgetType } from "../../generated";

interface WidgetFormProps {
  tenant_id: string;
  timeseries: TimeSeries[];
  updateWidget: (widget: WidgetType) => void;
  initialWidget?: WidgetType;
}

const getChartAssignments = (apiClient: BackendClient, tenant_id: string, timeseries_identifier: string) => {
  if (!tenant_id || !timeseries_identifier) return [];
  return apiClient.chartAssignment.getListTenantsTenantIdTimeseriesTimeseriesIdChartassignmentsGet(
    tenant_id,
    timeseries_identifier
  );
};

const WidgetForm = ({
  tenant_id,
  timeseries,
  updateWidget,
  initialWidget,
}: WidgetFormProps) => {
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFindLabelAndUnit = async () => {
    const timeseries_identifier = form.values.timeseries_identifier;
    if (!timeseries_identifier) {
      toast.error("Time Series is required");
      return;
    }
    setIsLoading(true);
    const chartAssignments = await getChartAssignments(
      apiClient,
      tenant_id,
      form.values.timeseries_identifier
    );
    if (chartAssignments.length > 0) {
      const label = chartAssignments[0].label;
      const unit = chartAssignments[0].group.unit || "";
      form.setFieldValue("label", label);
      form.setFieldValue("unit", unit);
      if (label && unit) {
        toast.success("Label and Unit found successfully");
      } else if (label) {
        toast.success("Label found successfully");
        toast.warning("No unit found for this time series");
      } else if (unit) {
        toast.success("Unit found successfully");
        toast.warning("No label found for this time series");
      }
    } else {
      toast.warning("No chart assignments found for this time series");
    }
    setIsLoading(false);
  }

  const form = useForm({
    initialValues: {
      label: initialWidget?.label || "",
      timeseries_identifier: initialWidget?.timeseries_identifier || "",
      order: initialWidget?.order || 0,
      unit: initialWidget?.unit || "",
      type: initialWidget?.type || "",
    },

    validate: {
      label: (value: string) => (value ? null : "Label is required"),
      timeseries_identifier: (value: string) =>
        value ? null : "Time Series is required",
    },
  });

  return (
    <Box mx="auto">
      <form onSubmit={form.onSubmit(updateWidget)}>
        <Select
          withAsterisk
          data={timeseries.map((ts) => ({ value: ts.identifier, label: ts.identifier }))}
          label="Time Series"
          placeholder="Time Series ID"
          searchable
          {...form.getInputProps("timeseries_identifier")}
        />

        <Button
          onClick={handleFindLabelAndUnit}
          loading={isLoading}
          mt="md"
        >
          Prefill Details
        </Button>

        <TextInput
          withAsterisk
          label="Label"
          placeholder="Widget Label"
          {...form.getInputProps("label")}
          mt="md"
        />

        <TextInput
          label="Unit"
          placeholder="Measurement Unit"
          {...form.getInputProps("unit")}
          mt="md"
        />

        <TextInput
          label="Type"
          placeholder="Type"
          {...form.getInputProps("type")}
          mt="md"
        />

        <Group mt="md">
          <Button type="submit">Save</Button>
        </Group>
      </form>
    </Box>
  );
};

export default WidgetForm;
