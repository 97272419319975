/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_differences_tenants__tenant_id__differences_post } from '../models/Body_differences_tenants__tenant_id__differences_post';
import type { Body_rebuild_tenants__tenant_id__rebuild_post } from '../models/Body_rebuild_tenants__tenant_id__rebuild_post';
import type { DashboardDifferences } from '../models/DashboardDifferences';
import type { GenericResponse } from '../models/GenericResponse';
import type { TaskStatus } from '../models/TaskStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DashboardService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Differences
     * Get the difference between the existing and new dashboard.
     * @param tenantId
     * @param requestBody
     * @param xTenantId
     * @returns DashboardDifferences Successful Response
     * @throws ApiError
     */
    public differencesTenantsTenantIdDifferencesPost(
        tenantId: string,
        requestBody: Body_differences_tenants__tenant_id__differences_post,
        xTenantId?: string,
    ): CancelablePromise<DashboardDifferences> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tenants/{tenant_id}/differences',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Hierarchy
     * Delete a hierarchy for a tenant.
     * @param tenantId
     * @param xTenantId
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteHierarchyTenantsTenantIdHierarchyDelete(
        tenantId: string,
        xTenantId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/tenants/{tenant_id}/hierarchy',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Task Status
     * Get the status of a background task.
     * @param tenantId
     * @param xTenantId
     * @param filePath
     * @returns TaskStatus Successful Response
     * @throws ApiError
     */
    public getTaskStatusTenantsTenantIdRebuildGet(
        tenantId: string,
        xTenantId?: string,
        filePath?: any,
    ): CancelablePromise<TaskStatus> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tenants/{tenant_id}/rebuild',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'X_tenant_id': xTenantId,
                'file_path': filePath,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Rebuild
     * Rebuild the full dashboard.
     * @param requestBody
     * @param background
     * @param xTenantId
     * @param subscription
     * @param filePath
     * @returns any Successful Response
     * @throws ApiError
     */
    public rebuildTenantsTenantIdRebuildPost(
        requestBody: Body_rebuild_tenants__tenant_id__rebuild_post,
        background: boolean = true,
        xTenantId?: string,
        subscription?: any,
        filePath?: any,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tenants/{tenant_id}/rebuild',
            query: {
                'background': background,
                'X_tenant_id': xTenantId,
                'subscription': subscription,
                'file_path': filePath,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Task Status
     * Reset the status of a background task.
     * @param tenantId
     * @param xTenantId
     * @param filePath
     * @returns TaskStatus Successful Response
     * @throws ApiError
     */
    public resetTaskStatusTenantsTenantIdRebuildResetPost(
        tenantId: string,
        xTenantId?: string,
        filePath?: any,
    ): CancelablePromise<TaskStatus> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tenants/{tenant_id}/rebuild/reset',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'X_tenant_id': xTenantId,
                'file_path': filePath,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Label
     * Update the dashboard label for a tenant.
     * @param tenantId
     * @param label
     * @param xTenantId
     * @returns GenericResponse Successful Response
     * @throws ApiError
     */
    public updateLabelTenantsTenantIdLabelPut(
        tenantId: string,
        label: string,
        xTenantId?: string,
    ): CancelablePromise<GenericResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/tenants/{tenant_id}/label',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'label': label,
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
