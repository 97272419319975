/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChartAssignment } from '../models/ChartAssignment';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ChartAssignmentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get List
     * Get chart assignments for a time series.
     * @param tenantId
     * @param timeseriesId
     * @param xTenantId
     * @returns ChartAssignment Successful Response
     * @throws ApiError
     */
    public getListTenantsTenantIdTimeseriesTimeseriesIdChartassignmentsGet(
        tenantId: string,
        timeseriesId: string,
        xTenantId?: string,
    ): CancelablePromise<Array<ChartAssignment>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tenants/{tenant_id}/timeseries/{timeseries_id}/chartassignments',
            path: {
                'tenant_id': tenantId,
                'timeseries_id': timeseriesId,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
