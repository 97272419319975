import { Paper, Text, Title } from "@mantine/core";
import UpdateStepper from "../../components/UpdateStepper";

const ExcelUpdatePage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">
        Rebuild Dashboard From Excel File
      </Title>
      <Text mb="xl">
        Update the dashboard step-by-step from an Excel file stored on SharePoint
      </Text>
      <UpdateStepper />
    </Paper>
  );
};

export default ExcelUpdatePage;
