import { graphConfig } from "./authConfig";
import combine from "./utils/combine";

/**
 * Create a new session for the Excel document.
 * @param accessToken Access token for Microsoft Graph API.
 * @param itemId ID of the Excel document.
 * @param persistChanges Whether to persist changes after session is closed.
 */
async function createSession(
  accessToken: string,
  itemId: string,
  persistChanges: boolean
) {
  const sessionEndpoint = combine(
    graphConfig.graphDriveEndpoint,
    itemId,
    "workbook/createSession"
  );

  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  });

  const body = JSON.stringify({ persistChanges });

  const response = await fetch(sessionEndpoint, {
    method: "POST",
    headers: headers,
    body: body,
  });

  const data = await response.json();
  return data.id;
}

/**
 * Fetch Excel data from a specific worksheet of an Excel document.
 * @param accessToken Access token for Microsoft Graph API.
 * @param itemId ID of the Excel document.
 */
export async function fetchMsGraphExcel(accessToken: string, itemId: string) {
  // Create a new session
  const sessionId = await createSession(accessToken, itemId, false);

  // Use current timestamp for cache busting
  const timeStamp = new Date().getTime();
  const excelEndpoint = combine(
    graphConfig.graphDriveEndpoint,
    itemId,
    "content?timestamp=" + timeStamp
  );

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("workbook-session-id", sessionId);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(excelEndpoint, options)
    .then((response) => response.arrayBuffer())
    .catch((error) => console.log(error));
}

