/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get List
     * Get list of users.
     * @param tenantId
     * @param xTenantId
     * @returns User Successful Response
     * @throws ApiError
     */
    public getListTenantsTenantIdUsersGet(
        tenantId: string,
        xTenantId?: string,
    ): CancelablePromise<Array<User>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/tenants/{tenant_id}/users',
            path: {
                'tenant_id': tenantId,
            },
            query: {
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create
     * Create an admin user for a tenant.
     * @param tenantId
     * @param userId
     * @param username
     * @param email
     * @param password
     * @param xTenantId
     * @returns User Successful Response
     * @throws ApiError
     */
    public createTenantsTenantIdUsersUserIdPost(
        tenantId: string,
        userId: string,
        username: string,
        email: string,
        password: string,
        xTenantId?: string,
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/tenants/{tenant_id}/users/{user_id}',
            path: {
                'tenant_id': tenantId,
                'user_id': userId,
            },
            query: {
                'username': username,
                'email': email,
                'password': password,
                'X_tenant_id': xTenantId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
