/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { aiomatic_warehouse__models__entity__tenant__Tenant__Reference } from './aiomatic_warehouse__models__entity__tenant__Tenant__Reference';
import type { aiomatic_warehouse__models__entity__timeseries__TimeSeries__Reference } from './aiomatic_warehouse__models__entity__timeseries__TimeSeries__Reference';

export type DataSource = {
    basetype?: DataSource.basetype;
    id: string;
    identifier: string;
    tenant: aiomatic_warehouse__models__entity__tenant__Tenant__Reference;
    label?: string;
    offset?: number;
    scale?: number;
    unit?: string;
    timeseries: aiomatic_warehouse__models__entity__timeseries__TimeSeries__Reference;
    node_tag: string;
};

export namespace DataSource {

    export enum basetype {
        DATA_SOURCE = 'DataSource',
    }


}

