import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UnstyledButton, Tooltip, Title, rem } from "@mantine/core";
import Logo from "../Logo";
import classes from "./Naviguation.module.css";
import navData from "../../pages/navData";
import { LinkType } from "../../types/naviguation.types";
import { NavType } from "../../types/naviguation.types";

function findActiveTab(pathname: string, navData: NavType[]): NavType {
  return (
    navData.find((tab) => tab.links.some((link) => link.link === pathname)) ||
    navData[0]
  );
}

function findActiveLink(pathname: string, activeTab: NavType): LinkType {
  return (
    activeTab.links.find((link) => link.link === pathname) || activeTab.links[0]
  );
}

function DoubleNavbar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const initialActiveTab = findActiveTab(pathname, navData);
  const initialActiveLink = findActiveLink(pathname, initialActiveTab);

  const [active, setActive] = useState<string>(initialActiveTab.label);
  const [activeLinks, setActiveLinks] = useState<LinkType[]>(
    initialActiveTab.links
  );
  const [activeLink, setActiveLink] = useState<LinkType>(initialActiveLink);

  const mainLinks = navData.map((data) => (
    <Tooltip
      label={data.label}
      position="right"
      withArrow
      transitionProps={{ duration: 0 }}
      key={data.label}
    >
      <UnstyledButton
        onClick={() => {
          setActive(data.label);
          setActiveLinks(data.links);
          setActiveLink(data.links[0]);
          navigate(data.links[0].link);
        }}
        className={classes.mainLink}
        data-active={data.label === active || undefined}
      >
        <data.icon style={{ width: rem(22), height: rem(22) }} stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  ));

  const links = activeLinks.map((link) => (
    <Link
      className={classes.link}
      data-active={activeLink === link || undefined}
      to={link.link}
      onClick={(e) => {
        setActiveLink(link);
      }}
      key={link.link}
    >
      {link.label}
    </Link>
  ));

  return (
    <nav className={classes.navbar}>
      <div className={classes.wrapper}>
        <div className={classes.aside}>
          <div className={classes.logo}>
            <Link to={"/"}>
              <Logo size={38} />
            </Link>
          </div>
          {mainLinks}
        </div>
        <div className={classes.main}>
          <Title order={4} className={classes.title}>
            {active}
          </Title>
          {links}
        </div>
      </div>
    </nav>
  );
}

export default DoubleNavbar;
