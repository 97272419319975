/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type aiomatic_warehouse__models__entity__tenant__Tenant__Reference = {
    basetype?: aiomatic_warehouse__models__entity__tenant__Tenant__Reference.basetype;
    id: string;
};

export namespace aiomatic_warehouse__models__entity__tenant__Tenant__Reference {

    export enum basetype {
        TENANT = 'Tenant',
    }


}

