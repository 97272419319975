import { Loader, Paper, Select } from "@mantine/core";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { useApiClient } from "../../ApiClientProvider";
import { BackendClient, SCWithStateAssignements } from "../../generated";
import StructuralComponentTree from "../StructuralComponentTree";

const UpdateStateAssignement = () => {
  const apiClient = useApiClient();

  const [selectedTenant, setSelectedTenant] = useState<string | null>("");
  const [structuralComponentSelection, setStructuralComponentSelection] =
    useState<string[]>([]);
  const [structuralData, setStructuralData] = useState<
    SCWithStateAssignements[]
  >([]);

  const {
    data: tenantData,
    error: tenantError,
    isLoading: isLoadingTenants,
  } = useSWR(["fetchAlltenants"], () => fetchAlltenants(apiClient));

  const ts_sc_cache_key = ["fetchStructuralComponents", selectedTenant];
  const {
    data: structuralComponentData,
    error: structuralError,
    isLoading: isLoadingStructuralComponents,
    isValidating: isFetchingStructuralComponents,
  } = useSWR(
    ts_sc_cache_key,
    () => fetchStructuralComponents(apiClient, selectedTenant),
    {
      errorRetryCount: 0,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: false,
    },
  );

  const handleTenantChange = (tenant: string | null) => {
    setSelectedTenant(tenant);
    setStructuralComponentSelection([]);
  };

  useEffect(() => {
    if (structuralComponentData) {
      setStructuralData(structuralComponentData);
    }
  }, [structuralComponentData]);

  if (tenantError) return <p>Error: {tenantError.message}</p>;
  if (structuralError) return <p>Error: {structuralError.message}</p>;
  if (isLoadingTenants || !tenantData) return <Loader mt="lg" />;
  const labels = tenantData.map((tenant) => tenant.identifier || "");

  return (
    <>
      <Paper withBorder p="md" radius="md">
        <Select
          mb="md"
          label="Tenant"
          placeholder="Select tenant"
          data={labels}
          value={selectedTenant}
          onChange={handleTenantChange}
        />
        {isLoadingStructuralComponents && <Loader />}
        {structuralData && (
          <StructuralComponentTree
            tenantId={selectedTenant || ""}
            structuralData={structuralData}
            setStructuralData={setStructuralData}
            selectedIdentifiers={structuralComponentSelection}
            setSelectedIdentifiers={setStructuralComponentSelection}
            isFetching={isFetchingStructuralComponents}
          />
        )}
      </Paper>
    </>
  );
};

export default UpdateStateAssignement;

const fetchAlltenants = (apiClient: BackendClient) =>
  apiClient.tenant.getListTenantsGet();

const fetchStructuralComponents = (
  apiClient: BackendClient,
  tenant: string | null,
) => {
  if (!tenant) return;
  return apiClient.structuralComponent.getWithStateAssignmentsTenantsTenantIdStructuralcomponentsGet(
    tenant,
  );
};
