import { Text } from '@mantine/core';
import React from 'react';
import classes from './ProgressBar.module.css';

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  return (
    <div className={classes.progressBar}>
      <div className={classes.progressBarFill} style={{ width: `${progress}%` }} />
      <Text>{progress}%</Text>
    </div>
  );
};

export default ProgressBar;