/**
 * Combines multiple path segments into a single, unified path.
 *
 * The function ensures that:
 * - Leading and trailing slashes or backslashes from each segment are removed.
 * - All backslashes are replaced with forward slashes.
 * - Path segments are joined with a single forward slash.
 *
 * @example
 * combine("/root/", "/path/to/", "resource/") => "root/path/to/resource"
 * combine("C:\\root", "path\\to", "resource") => "C:/root/path/to/resource"
 *
 * @param {...any[]} paths - The path segments to combine.
 * @returns {string} The combined path with unified slashes.
 */
const combine = (...paths: any[]) => {
  return paths
    .map((path: any) => path.replace(/^[\\|/]/, "").replace(/[\\|/]$/, ""))
    .join("/")
    .replace(/\\/g, "/");
};

export default combine;
