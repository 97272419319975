import { Paper, Text, Title } from "@mantine/core";
import ParameterClassifier from "../../components/ParameterClassifier";
import PickerWrapped from "../../components/PickerWrapped";

const ClassifierPage = () => {
  return (
    <Paper withBorder p={40} mt={40} radius="md">
      <Title order={2} mb="md">Classifier</Title>
      <Text>
        Classify parameters from the selected Excel file with Azure GPT-3 model
      </Text>
      <PickerWrapped title="SharePoint" />
      <ParameterClassifier />
    </Paper>
  );
};

export default ClassifierPage;
